import { handleCarousel, handleCarouselReviews } from './plugins/carouselOpinions';
import { handleSubmitForm } from './plugins/handleSubmitForm';

(function( $ ) {
  window.addEventListener('DOMContentLoaded', function () {
    handleCarousel();
    handleCarouselReviews();
    handleSubmitForm();
    handleCookieConsent();

    $('#menu-button').on('click', function (event) {
      event.preventDefault();
      $('nav').toggle();
      $('#menu-button').toggleClass('close');
    });

    $(window).resize(function(){
      if(window.innerWidth > 992) {
        $('nav').show();
        $('#menu-button').addClass('close');
      } else {
        $('nav').hide();
        $('#menu-button').removeClass('close');
      }
    });
  });

})( jQuery );

function setCookie(cname, cvalue, exdays) {
  const currentDate = new Date();
  currentDate.setTime(currentDate.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = "expires="+currentDate.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  const name = cname + "=";
  const cookies = document.cookie.split(';');
  for(let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}

function handleCookieConsent() {
  // Cookie consent
  if (!getCookie('cookieConsent')) {
    $('#cookie-consent').show();
  }

  document.querySelector('#cookie-consent-btn').addEventListener('click', function (event) {
    event.preventDefault();
    setCookie('cookieConsent', 1, 365);
    $('#cookie-consent').hide();
  })
}
