export const handleCarousel = () => {
    const carousel = document.querySelector('.opinions-home');

    if (carousel) {
        $('.opinions-content').slick({
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            mobileFirst: true,
            focusOnSelect: true,
            nextArrow: $('.opinions-button-next'),
            prevArrow: $('.opinions-button-prev'),
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                }
            ],
        });
    }
}

export const handleCarouselReviews = () => {
    const carousel = document.querySelector('.opinion-reviews');

    if (carousel) {
        $('.opinions-content').slick({
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            mobileFirst: true,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                    arrows: true,
                    mobileFirst: true,
                    focusOnSelect: true,
                    nextArrow: $('.opinions-button-next'),
                    prevArrow: $('.opinions-button-prev'),
                    }
                }
            ],
        });
    }
}
